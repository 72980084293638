
<template>
  <div>
    <HeaderLayout></HeaderLayout>

    <!--Container 3 -->

    <div style="clear: both; display: block; height: 0px;"></div>

    <!--Container - Popular Resorts -->
    <div class="w-full mx-auto pt-0 bg-white">
      <div
        class="flex w-full  bg-gradient-to-r from-gray-100 via-blue-100 to-green-100 text-left"
      >
        <h1
          class="text-2xl font-bold my-8 max-w-7xl md:mx-auto md:pl-4 mx-auto px-2 sm:px-6"
        >
          Agents
        </h1>
      </div>
      <div class="max-w-7xl mx-auto px-4 mt-20 mb-20 sm:px-6">
        <div
          class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4"
        >
          <div
            class="flex flex-col items-center justify-center bg-white p-4 shadow rounded-lg"
          >
            <div
              class="inline-flex shadow-lg border border-gray-200 rounded-full overflow-hidden h-40 w-40"
            >
              <img src="/img/Picture1.png" alt="" class="h-full w-full" />
            </div>

            <h2 class="mt-4 font-bold text-xl">Marjory D. Bedico</h2>
            <h6 class="mt-2 text-sm font-medium">Dubai</h6>

            <p class="text-xs text-gray-500 text-center mt-3">
              Mobile No. 0566217021<br />
              Whatsapp No. +971 56 224 8282<br />
              Email:
            </p>

            <ul class="flex flex-row mt-4 space-x-2">
              <li>
                <a
                  href=""
                  class="flex items-center justify-center h-8 w-8 border rounded-full text-gray-800 border-gray-800"
                >
                  <i class="fab fa-facebook"></i>
                </a>
              </li>
              <li>
                <a
                  href=""
                  class="flex items-center justify-center h-8 w-8 border rounded-full text-gray-800 border-gray-800"
                >
                  <i class="fab fa-twitter"></i>
                </a>
              </li>
              <li>
                <a
                  href=""
                  class="flex items-center justify-center h-8 w-8 border rounded-full text-gray-800 border-gray-800"
                >
                  <i class="fab fa-instagram"></i>
                </a>
              </li>
            </ul>
          </div>

          <div
            class="flex flex-col items-center justify-center bg-white p-4 shadow rounded-lg"
          >
            <div
              class="inline-flex shadow-lg border border-gray-200 rounded-full overflow-hidden h-40 w-40"
            >
              <img src="/img/Picture2.png" alt="" class="h-full w-full" />
            </div>

            <h2 class="mt-4 font-bold text-xl">Arlyn Char Galvan Uy</h2>
            <h6 class="mt-2 text-sm font-medium">Dubai</h6>

            <p class="text-xs text-gray-500 text-center mt-3">
              Mobile No. 0566217021<br />
              Whatsapp No. +971 56 224 8282<br />
              Email:
            </p>

            <ul class="flex flex-row mt-4 space-x-2">
              <li>
                <a
                  href=""
                  class="flex items-center justify-center h-8 w-8 border rounded-full text-gray-800 border-gray-800"
                >
                  <i class="fab fa-facebook"></i>
                </a>
              </li>
              <li>
                <a
                  href=""
                  class="flex items-center justify-center h-8 w-8 border rounded-full text-gray-800 border-gray-800"
                >
                  <i class="fab fa-twitter"></i>
                </a>
              </li>
              <li>
                <a
                  href=""
                  class="flex items-center justify-center h-8 w-8 border rounded-full text-gray-800 border-gray-800"
                >
                  <i class="fab fa-instagram"></i>
                </a>
              </li>
            </ul>
          </div>

          <div
            class="flex flex-col items-center justify-center bg-white p-4 shadow rounded-lg"
          >
            <div
              class="inline-flex shadow-lg border border-gray-200 rounded-full overflow-hidden h-40 w-40"
            >
              <img src="/img/Picture3.png" alt="" class="h-full w-full" />
            </div>

            <h2 class="mt-4 font-bold text-xl">Ma. Theressa Streegan</h2>
            <h6 class="mt-2 text-sm font-medium">Philippines</h6>

            <p class="text-xs text-gray-500 text-center mt-3">
              Mobile No: +63 945 381 6439<br />
              Whatsapp No. +63 945 381 6439<br />
              Email:
            </p>

            <ul class="flex flex-row mt-4 space-x-2">
              <li>
                <a
                  href=""
                  class="flex items-center justify-center h-8 w-8 border rounded-full text-gray-800 border-gray-800"
                >
                  <i class="fab fa-facebook"></i>
                </a>
              </li>
              <li>
                <a
                  href=""
                  class="flex items-center justify-center h-8 w-8 border rounded-full text-gray-800 border-gray-800"
                >
                  <i class="fab fa-twitter"></i>
                </a>
              </li>
              <li>
                <a
                  href=""
                  class="flex items-center justify-center h-8 w-8 border rounded-full text-gray-800 border-gray-800"
                >
                  <i class="fab fa-instagram"></i>
                </a>
              </li>
            </ul>
          </div>

          <div
            class="flex flex-col items-center justify-center bg-white p-4 shadow rounded-lg"
          >
            <div
              class="inline-flex shadow-lg border border-gray-200 rounded-full overflow-hidden h-40 w-40"
            >
              <img src="/img/Picture101.png" alt="" class="h-full w-full" />
            </div>

            <h2 class="mt-4 font-bold text-xl">Daniel Gamale Esling</h2>
            <h6 class="mt-2 text-sm font-medium">Abu Dhabi</h6>

            <p class="text-xs text-gray-500 text-center mt-3">
              Mobile No: +971558284864 <br />
              Whatsapp No: +971522665687 <br />
              Email:
            </p>

            <ul class="flex flex-row mt-4 space-x-2">
              <li>
                <a
                  href=""
                  class="flex items-center justify-center h-8 w-8 border rounded-full text-gray-800 border-gray-800"
                >
                  <i class="fab fa-facebook"></i>
                </a>
              </li>
              <li>
                <a
                  href=""
                  class="flex items-center justify-center h-8 w-8 border rounded-full text-gray-800 border-gray-800"
                >
                  <i class="fab fa-twitter"></i>
                </a>
              </li>
              <li>
                <a
                  href=""
                  class="flex items-center justify-center h-8 w-8 border rounded-full text-gray-800 border-gray-800"
                >
                  <i class="fab fa-instagram"></i>
                </a>
              </li>
            </ul>
          </div>

          <div
            class="flex flex-col items-center justify-center bg-white p-4 shadow rounded-lg"
          >
            <div
              class="inline-flex shadow-lg border border-gray-200 rounded-full overflow-hidden h-40 w-40"
            >
              <img src="/img/Picture102.png" alt="" class="h-full w-full" />
            </div>

            <h2 class="mt-4 font-bold text-xl">Juvie Cabusas Mamacos</h2>
            <h6 class="mt-2 text-sm font-medium">Philippines</h6>

            <p class="text-xs text-gray-500 text-center mt-3">
              Mobile No: 0063 9776754461 <br />
              Whatsapp No: 00960 9686899 <br />
              Email:
            </p>

            <ul class="flex flex-row mt-4 space-x-2">
              <li>
                <a
                  href=""
                  class="flex items-center justify-center h-8 w-8 border rounded-full text-gray-800 border-gray-800"
                >
                  <i class="fab fa-facebook"></i>
                </a>
              </li>
              <li>
                <a
                  href=""
                  class="flex items-center justify-center h-8 w-8 border rounded-full text-gray-800 border-gray-800"
                >
                  <i class="fab fa-twitter"></i>
                </a>
              </li>
              <li>
                <a
                  href=""
                  class="flex items-center justify-center h-8 w-8 border rounded-full text-gray-800 border-gray-800"
                >
                  <i class="fab fa-instagram"></i>
                </a>
              </li>
            </ul>
          </div>

          <div
            class="flex flex-col items-center justify-center bg-white p-4 shadow rounded-lg"
          >
            <div
              class="inline-flex shadow-lg border border-gray-200 rounded-full overflow-hidden h-40 w-40"
            >
              <img src="/img/Picture103.png" alt="" class="h-full w-full" />
            </div>

            <h2 class="mt-4 font-bold text-xl">Sheila Manalo Galpo</h2>
            <h6 class="mt-2 text-sm font-medium">Dubai</h6>

            <p class="text-xs text-gray-500 text-center mt-3">
              Mobile No. 00971 567299267<br />
              Whatsapp No. 00971 567299267 <br />
              Email:
            </p>

            <ul class="flex flex-row mt-4 space-x-2">
              <li>
                <a
                  href=""
                  class="flex items-center justify-center h-8 w-8 border rounded-full text-gray-800 border-gray-800"
                >
                  <i class="fab fa-facebook"></i>
                </a>
              </li>
              <li>
                <a
                  href=""
                  class="flex items-center justify-center h-8 w-8 border rounded-full text-gray-800 border-gray-800"
                >
                  <i class="fab fa-twitter"></i>
                </a>
              </li>
              <li>
                <a
                  href=""
                  class="flex items-center justify-center h-8 w-8 border rounded-full text-gray-800 border-gray-800"
                >
                  <i class="fab fa-instagram"></i>
                </a>
              </li>
            </ul>
          </div>

          <div
            class="flex flex-col items-center justify-center bg-white p-4 shadow rounded-lg"
          >
            <div
              class="inline-flex shadow-lg border border-gray-200 rounded-full overflow-hidden h-40 w-40"
            >
              <img src="/img/Picture104.png" alt="" class="h-full w-full" />
            </div>

            <h2 class="mt-4 font-bold text-xl">Winnie Dionisio Felix</h2>
            <h6 class="mt-2 text-sm font-medium">Abu Dhabi</h6>

            <p class="text-xs text-gray-500 text-center mt-3">
              Mobile No. 00971 509482672<br />
              Whatsapp No. 00971 509482672 <br />
              Email:
            </p>

            <ul class="flex flex-row mt-4 space-x-2">
              <li>
                <a
                  href=""
                  class="flex items-center justify-center h-8 w-8 border rounded-full text-gray-800 border-gray-800"
                >
                  <i class="fab fa-facebook"></i>
                </a>
              </li>
              <li>
                <a
                  href=""
                  class="flex items-center justify-center h-8 w-8 border rounded-full text-gray-800 border-gray-800"
                >
                  <i class="fab fa-twitter"></i>
                </a>
              </li>
              <li>
                <a
                  href=""
                  class="flex items-center justify-center h-8 w-8 border rounded-full text-gray-800 border-gray-800"
                >
                  <i class="fab fa-instagram"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <FooterLayout> </FooterLayout>
  </div>
</template>

<script>
import axios from "axios";
import HeaderLayout from "./PageLayouts/Header.Layout.vue";
import FooterLayout from "./PageLayouts/Footer.Layout.vue";

export default {
  name: "AgentsPage",
  data() {
    return {
      properties: [],
      totalPages: "",
      currentPage: this.$route.query.page * 1 || 1
    };
  },
  mounted() {
    this.getProperties();
  },
  methods: {
    async getProperties() {
      try {
        const response = await axios.get(
          `${this.$customConfig.api_host}/api/v1/properties`
        );
        this.properties = response.data.properties;
        this.totalPages = response.data.totalPages;
        console.log("hello");
      } catch (e) {
        console.log(e);
      }
    }
  },
  nextPage() {
    if (this.canGoNextPage) {
      this.currentPage += 1;
      this.getProperties();
    }
  },
  previousPage() {
    if (this.canGoPreviousPage) {
      this.currentPage -= 1;
      this.getProperties();
    }
  },
  computed: {
    canGoNextPage() {
      return this.currentPage + 1 <= this.totalPages;
    },
    canGoPreviousPage() {
      return this.currentPage - 1 > 0;
    }
  },
  components: {
    HeaderLayout,
    FooterLayout
  }
};
</script>

<style scoped></style>
